<template>
    <div>
        <header class="project-header">
            <b-container v-if="project">
                <b-row align-h="center">
                    <b-col cols="12" md="11" lg="10">
                        <b-row>
                            <b-col class="breadcrumbs">
                                <b-link v-if="project.type === 'project'" to="/projects">Projekty</b-link>
                                <span class="separator">/</span><span v-html="project.title.rendered"></span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <p class="h3">{{ project.acf.intro }}</p>
                            </b-col>
                        </b-row>
                        <b-row v-if="project.acf.url" class="mt-5">
                            <b-col>
                                <a class="external" target="_blank" :href="project.acf.url">{{ project.acf.url }}<icon icon="link" /></a>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>
        </header>

        <section class="project-content">
            <b-container v-if="project">
                <b-row>
                    <b-col cols="12" lg="10" offset-lg="1">
                        <b-row style="position: relative">
                            <b-col class="content" v-html="project.acf.content"></b-col>
                        </b-row>
                        <b-row class="my-5" v-if="project.acf.gallery">
                            <b-col>
                                <vue-slick-carousel v-bind="settings">
                                    <template #prevArrow>
                                        <div><icon icon="arrow-left" /></div>
                                    </template>
                                    <template #nextArrow>
                                        <div><icon icon="arrow-right" /></div>
                                    </template>

                                    <div v-for="(item, i) in project.acf.gallery" :key="'g_'+i">
                                        <img :src="item.url" />
                                    </div>
                                </vue-slick-carousel>
                            </b-col>
                        </b-row>
                        <b-row v-if="project.acf.ue.logo" class="my-5">
                            <b-col class="text-center">
                                <img :src="project.acf.ue.logo.url"/>
                            </b-col>
                        </b-row>
                        <b-row v-if="project.acf.ue.disclaimer" class="disclaimer">
                            <b-col cols="12">
                                <hr/>
                            </b-col>
                            <b-col cols="12">
                                <p>{{ project.acf.ue.disclaimer }}</p>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col cols="12" lg="1" class="text-center">
                        <page-share></page-share>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section v-if="project && related_projects" class="projects decorated-top-grey">
            <b-container>
                <b-row class="mb-5">
                    <b-col class="text-center">
                        <h3>Poznaj inne projekty</h3>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <entity-block-listing :items="related_projects"></entity-block-listing>
                    </b-col>
                </b-row>
                <b-row class="mt-5">
                    <b-col class="text-center">
                        <b-link to="/projects">
                            <b-button variant="dark">Więcej projektów</b-button>
                        </b-link>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
    import EntityBlockListing from "../components/utility/EntityBlockListing";
    import { project_acf_mapper } from './../components/mixins'
    import PageShare from "../components/utility/PageShare";
    import Icon from '../components/utility/Icon';
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    // import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    export default {
        name: "Project",
        components: {PageShare, EntityBlockListing, Icon, VueSlickCarousel},
        mixins: [project_acf_mapper],
        data() {
            return {
                slide: 0,
                sliding: null,

                settings: {
                    arrows: true,
                    dots: true,
                },
            }
        },
        computed: {
            project() {
              let content
              if(this.$route.params.slug === 'preview') {
                content = this.$store.getters.projectById(this.$route.query.post_id)
              } else {
                content = this.$store.getters.project(this.$route.params.slug)
              }
              if(content && content.title) {
                document.title = content.title.rendered + ' - Cyfrowy Dialog'
              }
              return content;
            },
            related_projects() {
                if(!this.project) return false;
                return this.map_projects_from_acf(this.project.acf.related_projects)
            }
        },
        mounted() {
          if(this.$route.params.slug === 'preview') {
            this.$store.dispatch('getProjectById', this.$route.query.post_id)
          } else {
            this.$store.dispatch('getProject', this.$route.params.slug)
          }
        }
    }
</script>

<style lang="scss">
    img.alignleft{
        float: left;
        max-width: 100%;
        height: auto;
        padding: 8px;

        @include media-breakpoint-up(md){ padding: 16px 16px 16px 8px; }
    }

    img.alignright{
        float: right;
        max-width: 100%;
        height: auto;
        padding: 8px;

        @include media-breakpoint-up(md){ padding: 16px 8px 16px 16px; }
    }

    section.projects {
        padding-top: 80px;
        padding-bottom: 160px;
        background: linear-gradient(180deg, $gray-100 0%, rgba($white, 0) 100%);

        img{ max-width: 100%; }
    }

    .project{
        img{ max-width: 100%; }

        &-header{
            background: $primary;
            color: $white;
            padding-top: 5rem;
            padding-bottom: 5rem;

            a{
                color: $white;
            }

            .breadcrumbs{
                margin-bottom: 3rem;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.3px;

                .separator{ margin: 0 0.75rem; }
            }

            .external{
                padding: 10px 20px 10px 16px;
                background: rgba($white, 0.1);
                display: inline-flex;
                align-items: center;
            }
        }

        &-content{
            padding-top: 104px;
            padding-bottom: 120px;

            .slick{
                &-slider{}

                &-track{
                    align-items: center;
                    display: flex !important;
                }

                &-slide{
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    font-size: 0; //to delete white-spaces

                    img{
                        max-width: 100%;
                        max-height: 80vw;

                        @include media-breakpoint-up(lg){ max-height: 560px; }
                    }
                }

                &-arrow{
                    &.slick-prev, &.slick-next{
                        display: flex;
                        background: none;
                        border: none;
                        position: absolute;
                        top: 50%;
                        z-index: 2;
                        transform: translateY(-50%);
                        padding: 12px;
                        background: $white;
                        border-radius: 100%;
                        border: 1px solid $gray-100;
                        cursor: pointer;
                        &, *{ transition: 0.3s ease; }

                        svg{
                            width: 20px;
                            height: 20px;
                            path{ fill: $black; }
                        }

                        &:hover{
                            background: $gray-100;
                            svg{ opacity: 1; }
                        }

                        @include media-breakpoint-up(lg){
                            padding: 20px;

                            svg{
                                opacity: 0.4;
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }

                    &.slick-prev{
                        left: 8px;

                        @include media-breakpoint-up(md){ left: 16px; }
                        @include media-breakpoint-up(lg){ left: 24px; }
                    }

                    &.slick-next{
                        right: 8px;

                        @include media-breakpoint-up(md){ right: 16px; }
                        @include media-breakpoint-up(lg){ right: 24px; }
                    }
                }

                &-dots{
                    display: flex !important;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    bottom: 24px;
                    left: 0;
                    right: 0;
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    li{
                        background: $gray-300;
                        border-radius: 100%;
                        width: 10px;
                        height: 10px;
                        transition: 0.3s ease;
                        cursor: pointer;

                        &:not(:last-of-type){
                            margin-right: 10px;

                            @include media-breakpoint-up(lg){ margin-right: 20px; }
                        }

                        button{ display: none; }

                        &.slick-active{
                            background: $primary;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }

            hr {
                margin: 0;
                border-color: $gray-300;
            }

            .disclaimer{
                margin-top: 48px;

                p{
                    @extend %header-6;
                    color: $gray-800;
                    margin: 48px 0 0;
                }
            }
        }
    }


    a.external{
        .icon{
            width: 16px;
            height: 16px;
            margin-left: 16px;

            path{ fill: $white; }
        }
    }

    // Contrast switch on
    .contrast{
        .project{
            &-header{
                .external{ background: darken($primary, 8); }
            }

            &-content{
                hr{ border-color: $gray-500; }
                .disclaimer p{ color: $black; }
            }
        }
    }
</style>
